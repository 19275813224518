$baseColor: #8bc34a;
$themeColor: #dcedc8;
$theme2Color: #424242;
$errorColor: #c62828;
$pageColor: #f9fbe7;
$fontColor: #424242;
$fontSecondaryColor: orange;
$stripedColor: #eeeeee;

$selectedColor: lighten($baseColor, 40);

$theme-colors: (
  "primary": $baseColor,
);

@import "~bootstrap/scss/bootstrap.scss";
