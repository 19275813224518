@use '~react-widgets/scss/styles.scss' with (
$input-height: 1.8rem, // at 16px, this an even 40px
//$widget-bg:$themeColor,
$widget-font-size: 0.75rem,
$widget-hover-bg: $themeColor,
$widget-focus-color: $themeColor,
$input-focus-border-color:$themeColor,
$widget-border-radius: .3rem,
);

//react-widget dropdownlist customization
.custom-dropdown .rw-input,
.custom-datepicker .rw-input,
.custom-dropdown .rw-widget-input,
.custom-dropdown .rw-widget-picker {
  border: 0.01rem solid #ced4da i !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  height: 2.4rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.custom-datepicker .rw-input {
  width: 9rem !important;
}

.custom-dropdown li.rw-list-option {
  font-size: 1rem !important;
  height: 2rem !important;
}

.custom-dropdown .rw-state-selected,
.custom-dropdown .rw-list-option .rw-state-selected {
  background-color: $baseColor !important;
  border: 0.01rem solid $baseColor !important ;
}

//react-widget dropdownlist customization
.custom-dropdown .rw-input {
  border: 0.01rem solid #ced4da i !important;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  height: 2.1rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.custom-dropdown li.rw-list-option {
  font-size: 1rem !important;
  height: 2rem !important;
}

.custom-dropdown-small {
  width: 6.9rem;
}
