* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

html,
body {
  //background-color: $pageColor;
  //font-family: Helvetica, sans-serif, Arial !important;
  font-size: 90% !important;
  line-height: 1.2rem;
  overflow: hidden;
  color: $fontColor;
}

// button,
// input,
// optgroup,
// select,
// textarea {
//   font-family: Helvetica, sans-serif, Arial !important;
// }

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.full-width {
  height: 100%;
  min-height: 1000px !important;
}
