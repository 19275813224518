nav,
footer {
  background: $themeColor !important;
}

.nav-btn,
.nav-btn-selected {
  font-size: $xmfont;
  font-weight: 600;
}

.nav-btn {
  //border-color: 0.05rem solid white;
  color: $fontColor !important;
  text-transform: none !important;
}

.nav-btn-selected {
  background: $selectedColor !important;
  border-radius: 0.2rem;
  color: $fontSecondaryColor !important;
}

.nav-btn-secondary {
  color: $fontColor !important;
  font-weight: 500;
  font-size: 0.75rem;
}

.nav-btn:hover,
.nav-btn-secondary:hover {
  background: none !important;
  //border-radius: 0.5rem;
  color: $fontSecondaryColor !important;
}

.page-footer {
  color: $fontColor !important;
}
