.col-fixed {
  position: relative;
  //min-height: 1rem;
  height: 100vh;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  float: left;
  width: 15vw;
}

.col-fluid {
  position: relative;
  //min-height: 1rem;
  height: 100vh;
  padding-right: 1.5rem;
  padding-left: 0.5rem;
  float: left;
  //width: calc(100% - 15rem);
  width: 85vw;
}

.pageColor {
  height: 100vh;
  width: 100vw;
  background: $pageColor !important;
}

//Input
textarea:focus,
input:focus,
input[type]:focus,
.uneditable-input:focus {
  border-color: $themeColor;
  box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset, 0 0 8px $themeColor;
  outline: 0 none;
}
