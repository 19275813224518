.sidenav {
  background: $themeColor;
  max-height: 93vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidenavBorder {
  padding-left: 1.2rem !important;
  //box-shadow: 0 4px 4px rgba(0, 0, 0, 0.86);
  box-shadow: 0 0.5rem 1rem 4px rgba(0, 0, 0, 0.15);
  clip-path: inset(0px -15px 0px 0px);
}

//box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15)

//Side Nav Buttons

.sidenav-btn,
.sidenav-btn-selected {
  font-size: $xmfont;
  font-weight: 600;
}

.sidenav-btn {
  color: $fontColor !important;
  text-transform: none !important;
}

.sidenav-btns,
.sidenav-btns-selected {
  @extend .sidenav-btn;
  font-size: $mfont;
  padding-left: 0.5rem;
}

.sidenav-btns1 {
  @extend .sidenav-btn;
  //padding-left: 0.5rem;
  font-size: $sfont;
  font-weight: 400;
}

.sidenav-btn-selected {
  color: $fontSecondaryColor !important;
}

.sidenav-btns-selected {
  @extend .sidenav-btn-selected;
  // font-size: $mfont;
  // padding-left: 0.5rem;
}

.sidenav-btns1-selected {
  @extend .sidenav-btn-selected;
  font-size: $sfont;
}

.sidenav-btn:hover,
.sidenav-btn-selected:hover {
  background: none !important;
  color: $fontSecondaryColor !important;
  cursor: pointer;
}

.sidenav-ul {
  margin-top: 2.4rem;
  line-height: 2.4rem !important;
}
